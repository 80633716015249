<template>
  <v-row dense>
    <v-col cols="6">
    <v-menu
      ref="till"
      v-model="showPicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formatted"
          :disabled="disabled"
          :label="hideDetails ? null : label"
          :hint="hideDetails ? null : formattedHint"
          :persistent-hint="!hideDetails"
          :hide-details="hideDetails"
          prepend-icon="fa-calendar"
          readonly
          v-on="on"
        ></v-text-field>
        <p v-if="showQuickLinks" class="pb-0 ml-8 text--small mt-1">
          {{ $t('shared.select') }}
          <a @click="selectDay(0)" class="me-1">{{ $t('shared.today') | lowercase }}</a>
          <a @click="selectDay(-1)">{{ $t('shared.yesterday') | lowercase }}</a>
        </p>
      </template>
      <v-date-picker 
        no-title 
        :show-current="false"
        :disabled="disabled"
        v-bind:value="value"
        v-on:input="handleChange"
        :min="minDate"
        :max="maxDate"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-col cols="6">
        <v-input
          :disabled="disabled"
          _prepend-icon="fa-clock"
          :hide-details="hideDetails"
          class="time-picker v-text-field "
        >
          <label v-if="!hideDetails" class="text-muted ">{{ timeLabel || 'Time of Day' }}</label>
          <input v-if="disabled" disabled="disabled" readonly="readonly" type="text" :value="timeFormatted"/>
          <vue-timepicker v-else v-model="timeFormatted" :format="timePickerFormat" :minute-interval="10" @change="timeChangeHandler" class="time-picker"></vue-timepicker>
        </v-input>

    </v-col>
  </v-row>
</template>



<script>
import { DateTime, Duration, Interval } from 'luxon';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: "DateAndTimeWithTimeZonePicker",
  components: {
    VueTimepicker,
  },
  props: {
    value: String,
    label: String,
    timeLabel: String,
    timeZone: String,
    hideDetails: Boolean,
    forceShowTime: Boolean,
    disabled: Boolean,
    minDate: String,
    maxDate: String,
    showQuickLinks: Boolean,
  },
  data() {
    return {
      showPicker: false,
      showTimePicker: false,
      //time: null,
      valueWithTime: null,
      //timeFormat: 'h:mm A',
      //timeFormatLuxon: 't'
    };
  },
  async mounted() {
  },
  methods: {
    timeChangeHandler(e){
      console.log('Time changed to', e, 'value:', this.value, 'valueWithTime', this.valueWithTime);
      var duration = Duration.fromObject({ hours: e.data.HH, minutes: e.data.mm });
      //console.log('Time changing to ', duration);
      var date = DateTime.fromISO(this.value, { zone: this.timeZone }).startOf('day');
      var dateAndTime = date.set({
        hours: duration.hours,
        minutes: duration.minutes,
      });
      var prev = this.valueWithTime;

      this.valueWithTime = dateAndTime.toISO();
      //this.valueWithTime = date.plus(duration).toISO(); // note: this does NOT WORK when DST kicks in/out!
      console.log('$emit:input', this.valueWithTime, 'prev:', prev);
      this.$emit('input', this.valueWithTime); 
    },
    selectDay(daysInPast) {
      this.valueWithTime = DateTime.now().startOf('day').plus({ days: daysInPast });
    },
    handleChange(e) {
      var prev = this.valueWithTime;
      var date = DateTime.fromISO(e, { zone: this.timeZone }).startOf('day');
      this.valueWithTime = date.toISO();
      console.log('Storing raw date value', e, 'time part',  this.timeFormatted, 'parsed', this.valueWithTime);
      console.log('$emit:input', this.valueWithTime, 'prev:', prev);
      this.$emit('input', this.valueWithTime); 
//      this.$emit('input', e); 
      this.showPicker = false;
      this.showTimePicker = false;
    },
    getTodayAsString() {
      return DateTime.now().startOf('day').toISO();
    },
  },
  computed: {
    valueAsIsoDate() {
      console.log('DATE formatted', DateTime.fromISO(this.currentValue, { zone: this.timeZone }).toISODate() );
      return this.currentValue == null ? null : DateTime.fromISO(this.currentValue, { zone: this.timeZone }).toISODate();
    },
    formatted: {
      get: function() {
        return this.currentValue == null ? null : DateTime.fromISO(this.currentValue, { zone: this.timeZone }).toLocaleString(DateTime.DATE_SHORT);
      },
      set: function(v) {
        console.log('Changing formatted to', v, 'current value:', this.currentValue);
      }
    },
    formattedHint() {
      return this.currentValue == null || this.timeZone == null ? null : DateTime.fromISO(this.currentValue, { zone: this.timeZone }).toLocaleString(this.hasTime ? DateTime.DATETIME_FULL : DateTime.DATE_FULL);
    },
    timeFormatted: {
      get: function() {
        console.log('timeFormatted.get()', this.currentValue == null || !this.hasTime ? null : DateTime.fromISO(this.currentValue, { zone: this.timeZone }).toFormat('t'));
        return this.currentValue == null || !this.hasTime ? null : DateTime.fromISO(this.currentValue, { zone: this.timeZone }).toFormat('t');
      },
      set: function(v) {
        console.log('Changing time to', v);
      }
    },
    timePickerFormat() {
      return this.use12HourFormat ? 'h:mm A' : 'HH:mm';
    },
    use12HourFormat() {
      var nowString = DateTime.now().toFormat('t');
      return nowString.indexOf('AM') || nowString.indexOf('PM');
    },
    hasTime() {
      if (this.currentValue == null) return false;
      var dateTime = DateTime.fromISO(this.currentValue, { zone: this.timeZone });
      return this.forceShowTime || !(dateTime.hours == 0 && dateTime.minutes == 0);
    },
    currentValue() {
      return this.valueWithTime || this.value;
    }
  },
  watch: {
    value(newValue) {
      console.log('Loading date+time', newValue, this.currentValue);
      this.valueWithTime = newValue;
    },
    time(newTimeString) {
      var duration = Duration.fromISOTime(newTimeString);
      console.log('Time changing to ' + newTimeString, duration);
      var date = DateTime.fromISO(this.value, { zone: this.timeZone }).startOf('day');
      this.valueWithTime = date.plus(duration).toISO();
      this.$emit('input', this.valueWithTime); 
    }
  }
};
</script>

<style lang="scss">
.time-picker {
  label {position: absolute; top: -15px;}
  .vue__time-picker {display: flex; flex: 1 1 auto; margin-top:0px; top: 0px; }
  .vue__time-picker input.display-time { 
    border: 0; 
    line-height: 20px;
    padding: 8px 0 8px;
  }
  .vue__time-picker input.display-time.is-empty { 
    height: 32px;;
  }
  /*.v-text-field { border-bottom: ;}*/
}

@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .vue__time-picker-dropdown, .vue__time-picker .dropdown  { left: inherit !important; right: 15px !important; }
}
</style>

